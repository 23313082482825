import { gql } from "@apollo/client";

const ALL_CHANGELOGS = gql`
  query allChangelog($where: ChangelogFilter, $sort: [ChangelogSorting!]) @api(name: "sanity") {
    allChangelog(where: $where, sort: $sort) {
      _id
      date
      items {
        type
        title
        description
      }
    }
  }
`;

export default ALL_CHANGELOGS;
