import { useCallback } from "react";

import { DOCUMENT_SECTIONS_MODAL_NAME } from "components/modals/documents/ClientDocumentsSectionsModal";
import { CREATE_DOCUMENT_COUNSEL_MODAL_NAME } from "components/modals/documents/CreateDocumentCounselModal";
import { CREATE_DOCUMENT_COURT_MODAL_NAME } from "components/modals/documents/CreateDocumentCourtModal";
import { CREATE_DOCUMENT_LOCATION_MODAL_NAME } from "components/modals/documents/CreateDocumentLocationModal";
import { SELECT_CALCULATION_MODAL_NAME } from "components/modals/finances/SelectCalculationModal";

import useCourtInformation from "./me/useCourtInformation";
import useMe from "./useMe";
import useModal from "./useModal";

const useCreateDocumentFlow = (step = 0, { client }) => {
  const { me } = useMe();
  const { courtId, courtFile } = useCourtInformation(client);

  const { open: openLocation } = useModal(CREATE_DOCUMENT_LOCATION_MODAL_NAME);
  const { open: openCourt } = useModal(CREATE_DOCUMENT_COURT_MODAL_NAME);
  const { open: openCounsel } = useModal(CREATE_DOCUMENT_COUNSEL_MODAL_NAME);
  const { open: openCalculation } = useModal(SELECT_CALCULATION_MODAL_NAME);
  const { open: openSections } = useModal(DOCUMENT_SECTIONS_MODAL_NAME);

  const createDocumentFlow = useCallback(
    ({ submit, document, client, isManageSections, ...options }) => {
      const orgLocations = me?.professional?.organization?.locations || [];
      const finances = client?.finances || [];

      const { city, phone, postal, street1, residence } = orgLocations?.[0] || {};
      const { supportCalculation, taxYear } = finances?.[0] || {};

      const props = { submit, document, client, isManageSections, ...options };

      const shouldOpenLocation = !city || !phone || !postal || !street1 || !residence;
      const shouldOpenCourt = !courtId || !courtFile;
      const shouldOpenCounsel = !client?.exLawyer && !client?.exProfile?.isSelfRepresented;

      const shouldOpenCalculation = document?.require_support_calculation && !supportCalculation;
      const shouldOpenSections = !!document?.sections?.length && isManageSections;

      if (shouldOpenLocation && step < 1) {
        openLocation(props);
      } else if (shouldOpenCourt && step < 2) {
        openCourt(props);
      } else if (shouldOpenCounsel && step < 3) {
        openCounsel(props);
      } else if (shouldOpenCalculation && step < 4) {
        openCalculation({
          ...props,
          clientId: client?.id,
          finances: client?.finances?.[0],
          taxYear,
          isCreateDocumentFlow: true,
        });
      } else if (shouldOpenSections && step < 5) {
        openSections({ sections: document?.sections || [], submit, client });
      } else {
        submit();
      }
    },
    [
      courtFile,
      courtId,
      me?.professional?.organization?.locations,
      openCalculation,
      openCounsel,
      openCourt,
      openLocation,
      openSections,
      step,
    ],
  );

  return createDocumentFlow;
};

export default useCreateDocumentFlow;
