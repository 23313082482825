import React, { useMemo, useRef, useState } from "react";

import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { Spinner } from "reactstrap";
import useOnClickOutside from "use-onclickoutside";

import ALL_CHANGELOGS from "graphql/sanity/allChangelogs";

import Button from "components/common/Button";

const ChangelogList = ({ items }) => {
  return (
    <div className="items-container">
      {items.map((item) => (
        <div className="changelog-item" key={item.title}>
          <div className="item-header">
            <span className={classNames("type", item.type.toLowerCase())}>{item.type}</span>
            <p className="title">{item.title}</p>
            <div className="clearfix" />
          </div>
          <div className="item-body">
            <p className="description">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const itemTypeMap = {
  fix: "NEW",
  improvement: "IMPROVE",
  bug: "FIX",
};

const ChangelogPopover = () => {
  const [isOpen, setIsOpen] = useState(false);

  const popoverRef = useRef(null);
  const buttonRef = useRef(null);

  useOnClickOutside(popoverRef, (event) => {
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      return;
    }

    setIsOpen(false);
  });

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const { data, loading } = useQuery(ALL_CHANGELOGS, {
    variables: { sort: [{ date: "ASC" }] },
  });

  const changelogItems = useMemo(() => {
    if (loading || !data?.allChangelog?.length) return [];

    return data?.allChangelog?.reduce((acc, logsByDate) => {
      const { date, items } = logsByDate;

      const formattedItems = items.map((item) => ({
        ...item,
        date,
        type: itemTypeMap[item.type],
      }));

      return [...acc, ...formattedItems];
    }, []);
  }, [loading, data]);

  return (
    <div className="changelog-popover-container">
      <div className="changelog-popover-btn-container" ref={buttonRef} onClick={handleClick}>
        <Button className="changelog-popover-btn" leftIcon="megaphone">
          {!!changelogItems.length && (
            <div className="items-count-badge">{changelogItems.length}</div>
          )}
        </Button>
      </div>
      <div ref={popoverRef} className={classNames("changelog-popover", { show: isOpen })}>
        <div className="changelog-popover-header">Latest Updates</div>
        <div className="changelog-popover-body">
          {!changelogItems.length && !loading && (
            <div className="no-items">
              <span>Information about updates will be displayed here</span>
            </div>
          )}
          {loading && (
            <div className="items-loading">
              <Spinner color="primary" size="sm" />
            </div>
          )}
          {!loading && !!changelogItems.length && <ChangelogList items={changelogItems} />}
        </div>
      </div>
    </div>
  );
};

export default ChangelogPopover;
